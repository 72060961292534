<template>
    <!-- 课程列表页面 -->
    <div class="padding24 color_white" id="course_list">
        <div v-show="$route.meta.isShow">
            <!-- 顶部 -->
            <div class="top">
                <div class="top_left">
                    <a-button icon="plus" type="primary" @click="addSourse" v-show="isShowAdd">新建课程</a-button>
                </div>

                <div class="top_rigiht">
                    <span>课程类型</span>
                    <a-select v-model="courseType" style="width: 120px" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="changeCourseType">
                        <a-select-option v-for="item in courseTypeArr" :key="item.key">{{ item.value }}</a-select-option>
                    </a-select>
                    <span>课程状态</span>
                    <a-select v-model="putawayFlag" style="width: 120px" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="changePutawayFlag">
                        <a-select-option v-for="item in putawayFlagArr" :key="item.key">{{ item.value }}</a-select-option>
                    </a-select>
                    <a-input ref="userNameInput" v-model="searchVal" placeholder="请输入课程名称" style="width: 200px" @pressEnter="searchClick">
                        <a-icon slot="prefix" type="search"/>
                    </a-input>
                    <a-button icon="search" type="primary" @click="searchClick" :disabled="isDisableSearch">搜索</a-button>
                </div>
            </div>
            <!-- 表格 -->
            <a-table :columns="columns" :data-source="tableData" :pagination="false" :loading="tableLoading">
                <span slot="number" slot-scope="number, record, index">
                   <span>{{ index + 1 }}</span>
                </span>
                <img v-viewer class="avatar" slot="coverUrl" slot-scope="coverUrl, record" :src="record.coverUrl"/>
                <span slot="putawayFlagText" slot-scope="putawayFlagText, record">
                    <a-badge v-show="record.putawayFlag == 0" status="error" text="已下架"/>
                    <a-badge v-show="record.putawayFlag == 1" status="processing" text="定时上架"/>
                    <a-badge v-show="record.putawayFlag == 2" status="success" text="已上架"/>
                    <a-badge v-show="record.putawayFlag == 3" status="warning" text="暂不上架"/>
                </span>
                <span slot="price" slot-scope="price, record">
                    <span v-show="record.pricingType == 0">免费</span>
                    <span v-show="record.pricingType == 1">{{ record.price }}</span>
                    <span v-show="record.pricingType == 2">不支持单独售卖</span>
                </span>
                <span slot="operation" slot-scope="operation, record">
                    <a v-show="(record.catalogueFlag == 1 && isShowCatalogueList) ||isShowCourseList" class="margin_right20" @click="contentManageClick(record)">内容管理</a>
                    <a v-show="isShowShare" class="margin_right20" @click="shareClick(record)">分享</a>
                    <a v-show="record.putawayFlag == 2 && isShowOut" class="margin_right20" @click="TapeOutClick(record)">下架</a>
                    <a v-show="record.putawayFlag != 2 && isShowOnLine" class="margin_right20" @click="TapeOutClick(record)">上架</a>
                    <a v-show="isShowUpd" class="margin_right20" @click="editCourse(record)">编辑</a>
                    <a id="copy_courseNo" :data-clipboard-text="record.courseNo" type="primary" class="margin_right20" @click="copyCourseNo">复制商品ID</a>
                    <a id="copy_courseNo" :data-clipboard-text="record.new" type="primary" class="margin_right20" @click="newCurriculumAttributes(record)">新版课程属性</a>
                    <a id="copy_memberNo" type="primary" class="margin_right20" @click="appShow(record.courseNo)">app显示控制</a>
                    <a v-show="isShowDel" class="margin_right20" @click="delClick(record)">删除</a>

                    <a @click="posterManage(record)" class="margin_right20">海报管理</a>
                    <a v-if="isShowGetAtlasList && record.courseNo == '1548912074522935296'" @click="relationShip(record)">关系图谱</a>
                    <router-link :to="{ name: 'StudyPlan', params: {courseNo: record.courseNo}}" class="margin_right20">学习规划</router-link>
                </span>
            </a-table>

            <!-- 分享的弹框 -->
            <a-modal v-model="shareModalVisible" :footer="null" width="40%">
                <div class="share">分享</div>
                <a-tabs default-active-key="1">
                    <a-tab-pane key="1" tab="H5">
                        <div>
                            <span style="margin-right: 20px">链接</span>
                            <a-input placeholder="H5链接" disabled style="width: 80%" v-model="shareInputVal"/>
                            <a-button id="copy_text" :data-clipboard-text="shareInputVal" type="primary" style="margin-left: 20px" @click="handleCopyFun">复制</a-button>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </a-modal>

            <!-- 分页功能 -->
            <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
            <a-modal title="新建/编辑标签" :width="640" :visible="visible" :confirmLoading="confirmLoading" okText="确定" cancelText="取消" @ok="handleOk" @cancel="handleCancel">
                <a-form-model ref="tapForm" :model="tapform" :label-col="{ span: 7 }" :wrapperCol="{ span: 13 }">
                    <a-form-model-item label="标签名称" prop="labelText">
                        <a-radio-group name="radioGroup" :value="tapform.showflag" @change="pointTypeChange">
                            <a-radio :value="1"> 显示</a-radio>
                            <a-radio :value="0"> 不显示</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-form-model>
            </a-modal>
            <!-- 返回顶部 -->

            <a-back-top :target="targetFn" :visibilityHeight="100"/>
        </div>
        <router-view/>
    </div>
</template>
<script>
// npm i clipboard --save  点击按钮复制文字
import Clipboard from "clipboard";
import {
    CourseInfotApi,
    CourseInfoDeleteApi,
    CourseShareCourseLinkApi,
    CourseInfosortOperationApi,
    CourseInfoOnLinetApi,
    CourseInfoOutApi,
    GetCourseAppShowApi,
    SetCourseAppShowApi
} from "@/request/api/coursManage";
import MyPagination from "@/components/pagination/MyPagination";
import {Modal, message} from "ant-design-vue";
import {codeFn} from "@/utils/tools";

export default {
    components: {MyPagination},
    created() {
        let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
        dictionaryObj.courseType.forEach((item) => this.courseTypeArr.push(item));
        dictionaryObj.putawayFlag.forEach((item) => this.putawayFlagArr.push(item));
        if (codeFn("/admin/course/info/saveCourse")) this.isShowAdd = true;
        if (codeFn("/admin/course/info/updCourse")) this.isShowUpd = true;
        if (codeFn("/admin/course/info/onLine")) this.isShowOnLine = true;
        if (codeFn("/admin/course/info/out")) this.isShowOut = true;
        if (codeFn("/admin/course/info/delete")) this.isShowDel = true;
        if (codeFn("/admin/course/info/shareCourseLink")) this.isShowShare = true;
        if (codeFn("/admin/course/catalogue/getCatalogueTreeResult")) {
            this.isShowCatalogueList = true;
        }
        if (codeFn("/admin/course/lessons/getCourseLessonsResult")) {
            this.isShowCourseList = true;
        }
        if (codeFn("/adminv2/getAtlasList")) {
            this.isShowGetAtlasList = true;
        }
    },
    data() {
        return {
            pageNo: 1, // 当前也
            pageSize: 20, // 每页条数
            tableLoading: true, // 表格loading图
            isDisableSearch: false, // 搜索按钮是否禁用
            searchVal: "", // 搜索框的值
            courseType: "", // 课程类型默认值
            courseTypeArr: [{key: "", value: "全部类型"}], //课程类型的数组
            putawayFlag: "", // 课程状态的默认值
            putawayFlagArr: [{key: "", value: "全部状态"}], // 课程状态数组
            count: 0, // 列表的总条数
            shareModalVisible: false, // 分享弹框
            shareInputVal: "", // 分享弹框的input值
            modalVal: 1, // 1代表下面有课程, 1 代表有上架的课程
            tapform: {
                showflag: 1
            },
            visible: false,
            confirmLoading: false,
            // 表格数据
            columns: [
                {
                    title: "序号",
                    dataIndex: "number",
                    key: "number",
                    width: "4%",
                    scopedSlots: {customRender: "number"},
                },
                {
                    title: "",
                    dataIndex: "coverUrl",
                    width: "4%",
                    key: "coverUrl",
                    scopedSlots: {customRender: "coverUrl"},
                },
                {
                    title: "课程名称",
                    dataIndex: "name",
                    width: "17%",
                    key: "name",
                },
                {
                    title: "课节数",
                    dataIndex: "lessonsCount",
                    width: "5%",
                    key: "lessonsCount",
                },
                {
                    title: "课程类型",
                    dataIndex: "courseTypeText",
                    width: "6%",
                    key: "courseTypeText",
                },
                {
                    title: "定价",
                    dataIndex: "price",
                    width: "9%",
                    key: "price",
                    scopedSlots: {customRender: "price"},
                },
                {
                    title: "销售量",
                    dataIndex: "salesCount",
                    width: "5%",
                    key: "salesCount",
                },
                {
                    title: "销售额",
                    dataIndex: "salesAmount",
                    width: "8%",
                    key: "salesAmount",
                },
                {
                    title: "状态",
                    dataIndex: "putawayFlagText",
                    width: "7%",
                    key: "putawayFlagText",
                    scopedSlots: {customRender: "putawayFlagText"},
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    width: "8%",
                    sorter: (a, b) => {
                        let aTime = new Date(a.createTime).getTime();
                        let bTime = new Date(b.createTime).getTime();
                        return aTime - bTime;
                    },
                },
                {
                    title: "操作",
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
            sourceObj: {}, // 表格源对象
            sourceIndex: null, // 表格源对象索引
            targetObj: {}, // 表格目标对象
            targetIndex: null, // 表格目标对象索引
            isShowAdd: false, // 新增是否隐藏
            isShowUpd: false, // 编辑是否隐藏
            isShowOnLine: false, // 上架是否隐藏
            isShowOut: false, // 下架是否隐藏
            isShowDel: false, // 删除是否隐藏
            isShowShare: false, // 分享是否隐藏
            isShowCatalogueList: false, // 章节列表是否隐藏
            isShowCourseList: false, // 课节列表是否隐藏
            isShowGetAtlasList: false, //关系图谱是否隐藏
        };
    },
    watch: {
        searchVal(newVal) {
            if (newVal.length <= 0) {
                this.courseInfoFn();
            }
        },
        $route: {
            handler(to) {
                if (to.path == "/coursManage/courseList") {
                    this.courseInfoFn();
                }
            },
            immediate: true,
        },
    },
    methods: {
        // 改变打点类型
        pointTypeChange(e) {
            this.tapform.showflag = e.target.value;
        },
        // 标签提交
        handleOk() {
            this.confirmLoading = true;
            SetCourseAppShowApi({
                courseNo: this.courseNo,
                appShow: this.tapform.showflag
            }).then((res) => {
                if (res.code === 200) {
                    this.visible = false;
                    this.confirmLoading = false;
                    this.$refs.tapForm.resetFields(); // 重置表格
                    message.success("修改成功");
                    this.CourseInfotApi();
                } else {
                    setTimeout(() => {
                        this.confirmLoading = false;
                    }, 2000);
                }
            });
        },
        // 标签取消或关闭
        handleCancel() {
            this.visible = false;
            this.$refs.tapForm.resetFields(); // 重置表格
        },
        appShow(no) {
            this.courseNo = no
            GetCourseAppShowApi({courseNo: no}).then(res => {
                if (res.code === 200) {
                    this.tapform.showflag = Number(res.data.appShow)
                }
            })
            this.visible = true;
        },
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course_list");
        },
        // 添加课程列表按钮
        addSourse() {
            this.$router.push("/coursManage/courseList/addSourse/0");
        },
        // 编辑课程列表按钮
        editCourse(record) {
            this.$router.push(`/coursManage/courseList/addSourse/${record.key}`);
        },
        // 课程类型改变的回调
        changeCourseType(value) {
            this.pageNo = 1;
            this.courseType = value;
            this.courseInfoFn();
        },
        // 课程状态改变的回调
        changePutawayFlag(value) {
            this.pageNo = 1;
            this.putawayFlag = value;
            this.courseInfoFn();
        },
        // 回车和搜索的按钮
        searchClick() {
            this.pageNo = 1;
            this.isDisableSearch = true;
            setTimeout(() => {
                this.isDisableSearch = false;
            }, 3000);
            this.courseInfoFn();
        },
        // 内容管理按钮
        contentManageClick(record) {
            localStorage.setItem("catalogueFlag", record.catalogueFlag);
            localStorage.setItem("activeKey", record.catalogueFlag);
            localStorage.setItem("courseNo", record.courseNo);
            localStorage.setItem("createTime", record.createTime);
            localStorage.setItem("name", record.name);
            localStorage.setItem("courseType", record.courseType);
            location.href = `/coursManage/courseList/contentManage`;
        },
        // 分享按钮
        shareClick(record) {
            this.shareModalVisible = true;
            CourseShareCourseLinkApi({courseNo: record.courseNo}).then(
                ({code, data}) => {
                    if (code == 200) {
                        this.shareInputVal = data.courseInfoLink;
                    }
                }
            );
        },
        // 分享弹框复制按钮
        handleCopyFun() {
            let clipboard = new Clipboard("#copy_text");
            clipboard.on("success", (e) => {
                message.success("复制成功");
                this.shareModalVisible = false;
                clipboard.destroy(); // 释放内存
            });
            clipboard.on("error", (e) => {
                message.error("复制失败"); // 不支持复制
                this.shareModalVisible = false;
                clipboard.destroy(); // 释放内存
            });
        },
        // 复制ID的按钮
        copyCourseNo() {
            let clipboard = new Clipboard("#copy_courseNo");
            clipboard.on("success", (e) => {
                message.success("复制成功");
                clipboard.destroy(); // 释放内存
            });
            clipboard.on("error", (e) => {
                message.error("复制失败"); // 不支持复制
                clipboard.destroy(); // 释放内存
            });
        },
        //新版课程属性
        newCurriculumAttributes(record) {
            this.$router.push(`/coursManage/courseList/newAttr/${record.courseNo}`);
        },
        // 上架or下架按钮
        TapeOutClick(record) {
            if (record.putawayFlag == 2) {
                Modal.confirm({
                    title: "提示",
                    content: "设为下架后,用户将不能再购买该课程,但是不影响学习,是否确定下架?",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                        CourseInfoOutApi({
                            id: record.key,
                        }).then(({code}) => {
                            if (code == 200) {
                                message.success("下架成功", 1);
                                record.putawayFlag = 0;
                            }
                        });
                    },
                });
            } else {
                CourseInfoOnLinetApi({id: record.key}).then(({code}) => {
                    if (code == 200) {
                        message.success("上架成功", 1);
                        record.putawayFlag = 2;
                    }
                });
            }
        },
        // 删除的按钮
        delClick(record) {
            let that = this;
            if (record.lessonsCount !== 0) {
                Modal.confirm({
                    title: "提示",
                    content: "该课程下存在课节,需要全部移出后才可删除",
                    okType: "danger",
                    okText: "确认",
                    cancelText: "取消",
                });
            } else if (record.putawayFlagText == "已上架") {
                Modal.confirm({
                    title: "提示",
                    content: "上架中的课程不能直接删除,请先下架",
                    okType: "danger",
                    okText: "确认",
                    cancelText: "取消",
                });
            } else {
                Modal.confirm({
                    title: "提示",
                    content: "删除课程后该课程将无法找回, 是否确认删除",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                        CourseInfoDeleteApi({courseNo: record.courseNo}).then(
                            ({code, data}) => {
                                if (code == 200) {
                                    message.success("删除成功", 2);
                                    let tableData = that.tableData;
                                    that.tableData = tableData.filter(
                                        (item) => item.courseNo !== record.courseNo
                                    );
                                }
                            }
                        );
                    },
                });
            }
        },
        //关系图谱
        relationShip(record) {
            location.href = `/coursManage/courseList/RelationShip`;
        },

        // 跳转海报管理
        posterManage(record){
            location.href = `/coursManage/courseList/PosterManage/${record.courseNo}`;
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.courseInfoFn();
        },
        // 封装请求课程列表的函数
        courseInfoFn() {
            CourseInfotApi({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                courseType: this.courseType, //课程类型
                putawayFlag: this.putawayFlag, //上架状态
                name: this.searchVal, //课程名称
            }).then(({code, data}) => {
                if (code == 200) {
                    this.tableLoading = false;
                    let newData = data.data.map((item) => {
                        return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
                    });
                    this.tableData = newData;
                    this.count = data.count;
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
#course_list {
    height: 100%; // -- 给定高度
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    display: flex;
    justify-content: space-between;

    .top_rigiht {
        span {
            margin-right: 10px;
        }
    }

    .ant-select {
        margin-right: 10px;
    }
}

.avatar {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.share {
    width: 95%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background-color: #efefef;
}

/deep/ .ant-table-wrapper {
    margin-top: 20px;
}

/deep/ .ant-table-thead > tr > th {
    font-weight: 600;
}
</style>
